import { DEFAULT_OPTIONS, certainlyDomains } from '../config';
import { IFRAME_ID_BOTXO, OWNER_POST_MESSAGE } from '../domUtils';
import { isArray } from './genericFunctions';
import { getValidCvars } from './helpers';

let certainlyDomain = DEFAULT_OPTIONS.env;
let isSetDomain = false;
const objOfKeys = {};
const objOfCallbacks = {};
const objOfCallbacksWithoutData = {};
const objOfOnceCallbacks = {};

// Certainly dataTransfer functions
const addCertainlyCallback = ({
  actionName,
  callback,
  once = false,
  withoutData = false,
}) => {
  if (once) {
    objOfOnceCallbacks[`${actionName}`] = [callback];
    return;
  }
  if (withoutData) {
    objOfCallbacksWithoutData[`${actionName}`] = [callback];
    return;
  }
  if (objOfCallbacks[`${actionName}`]) {
    objOfCallbacks[`${actionName}`].push(callback);
    return;
  }
  objOfCallbacks[`${actionName}`] = [callback];
};

export const setCertainlyDomain = rawOptions => {
  isSetDomain = true;
  const options = { ...DEFAULT_OPTIONS, ...rawOptions };
  const { webchatKey = '1', env } = options;
  certainlyDomain = certainlyDomains[env];
  if (!objOfKeys[webchatKey]) {
    window.addEventListener('message', event => {
      const { data: { message, actionName, cvars } } = event;
      const validCvars = getValidCvars(cvars);
      const action = `${actionName}${webchatKey}`;
      const callbacks = objOfCallbacks[`${action}`];
      const allCallback = objOfCallbacks[`*${webchatKey}`];
      const onceCallback = objOfOnceCallbacks[`${action}`];
      const callbackWithoutData = objOfCallbacksWithoutData[`${action}`];
      if (callbacks) {
        callbacks.forEach(callback => callback({ message, cvars: validCvars }));
      }
      if (allCallback && actionName) {
        allCallback.forEach(callback =>
          callback({ message, cvars: validCvars }),
        );
      }
      if (onceCallback) {
        onceCallback.forEach(callback => callback());
        delete objOfOnceCallbacks[`${action}`];
      }
      if (callbackWithoutData) {
        callbackWithoutData.forEach(callback => callback());
      }
    });
  }
  if (webchatKey) {
    objOfKeys[webchatKey] = 'exist';
  }
};

export const getCertainlyTransfer = ({
  actionName = '',
  webchatKey = '1',
  callback,
  env = 'prod',
  once,
  withoutData,
}) => {
  if (!isSetDomain) {
    setCertainlyDomain({
      webchatKey,
      env,
    });
  }
  if (typeof actionName === 'string') {
    addCertainlyCallback({
      actionName: `${actionName}${webchatKey}`,
      callback,
      once,
      withoutData,
    });
  } else if (isArray(actionName)) {
    actionName.forEach(action => {
      addCertainlyCallback({
        actionName: `${action}${webchatKey}`,
        callback,
        once,
        withoutData,
      });
    });
  }
};

export const dataCertainlyTransfer = (
  { data, webchatKey = '1', openChat = true },
  callback,
) => {
  const iFrame = document.getElementById(`${IFRAME_ID_BOTXO}${webchatKey}`);
  if (iFrame) {
    if (openChat) {
      window.postMessage({ message: `openChat-${webchatKey}` }, '*');
    }
    const objectToSend = {
      owner: OWNER_POST_MESSAGE,
      data: {
        ...data,
        webchatKey,
      },
    };

    iFrame.contentWindow.postMessage(objectToSend, certainlyDomain);

    if (typeof callback === 'function' && callback && data && data.mod_id) {
      getCertainlyTransfer({
        actionName: data.mod_id,
        webchatKey,
        callback,
        once: true,
        withoutData: true,
      });
    }
  }
};
