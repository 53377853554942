export const isArray = value => Array.isArray(value);

export const executeCallback = callbackFunction => {
  if (typeof callbackFunction === 'function' && callbackFunction) {
    callbackFunction();
  }
};

export const createWebInfoToScript = () => {
  const { location: { protocol, host, hostname, port } } = window;
  return {
    protocol,
    hostName: hostname,
    port,
    host,
    hostPageUrl: document.URL,
  };
};
