import { STORE_KEYS } from '../config';
import { disableBodyScroll, enableBodyScroll } from '../domFunctions';
import {
  BOTXO_BUTTON,
  CLOSE_CHAT,
  IFRAME_CONTAINER_ID_BOTXO,
  IFRAME_ID_BOTXO,
  OPEN_CHAT,
  OWNER_POST_MESSAGE,
  setStyle,
} from '../domUtils';
import { dataCertainlyTransfer, getCertainlyTransfer } from './dataTransfer';
import {
  fireOnWidgetClosed,
  fireOnWidgetOpened,
  fireWidgetClose,
  fireWidgetHidden,
  fireWidgetOpen,
  fireWidgetShown,
} from './events';
import { createWebInfoToScript, executeCallback } from './genericFunctions';
import { getValidCvars } from './helpers';

const POST_MESSAGE_GO_TO_MODULE = 'go_to_module';
const POST_MESSAGE_MESSAGE_BOT = 'message_bot';
const POST_MESSAGE_MESSAGE_USER = 'message_user';
const POST_MESSAGE_RESET_CHAT = 'reset_chat';
const POST_MESSAGE_CLEAR_CHAT = 'clear_chat';
const POST_MESSAGE_SCRIPT_GET_CONFIGURATION = 'script_get_configuration';
const POST_MESSAGE_SEND_CVARS = 'send_cvars';
const { SK_STATUS_WEBCHAT } = STORE_KEYS;

// Certainly Widget Actions
export const sendCvars = (isIframeLoaded, dataOptions) => {
  const {
    webchatKey,
    custom_vars,
    openChat,
    callback,
    ...restOptions
  } = dataOptions;

  const cvars = getValidCvars(custom_vars);

  if (isIframeLoaded) {
    dataCertainlyTransfer({
      data: {
        type: POST_MESSAGE_SEND_CVARS,
        custom_vars: cvars,
        ...restOptions,
      },
      webchatKey,
      openChat,
    });
    if (typeof callback === 'function' && callback) {
      callback();
    }
  } else {
    /* eslint-disable no-throw-literal */
    throw new Error(
      'The chat has not been loaded yet. It is not possible to send Custom variables',
    );
  }
};

export const widgetStatus = (dataOptions, callbackFunction) => {
  if (typeof dataOptions === 'object' && dataOptions !== null) {
    const { action, webchatKey, fireEvents = true } = dataOptions;
    let associatedWebchatKey = webchatKey;
    if (!webchatKey) {
      associatedWebchatKey = '1';
    }
    const chatButton = document.getElementById(
      `${BOTXO_BUTTON}${associatedWebchatKey}`,
    );
    const iframeContainer = document.getElementById(
      `${IFRAME_CONTAINER_ID_BOTXO}${associatedWebchatKey}`,
    );
    const isSmallScreen = window.innerWidth < 450;

    switch (action) {
      case 'open': {
        window.isWidgetOpen = true;
        window.postMessage({ message: `${OPEN_CHAT}${associatedWebchatKey}` });
        executeCallback(callbackFunction);
        if (fireEvents) {
          fireWidgetOpen({ from: 'SCRIPT', webchatKey: associatedWebchatKey });
          fireOnWidgetOpened({
            from: 'SCRIPT',
            webchatKey: associatedWebchatKey,
          });
        }
        break;
      }
      case 'close': {
        window.isWidgetOpen = false;
        window.postMessage({ message: `${CLOSE_CHAT}${associatedWebchatKey}` });
        executeCallback(callbackFunction);
        if (fireEvents) {
          fireWidgetClose({ from: 'SCRIPT', webchatKey: associatedWebchatKey });
          fireOnWidgetClosed({
            from: 'SCRIPT',
            webchatKey: associatedWebchatKey,
          });
        }
        break;
      }
      case 'show': {
        if (
          chatButton &&
          chatButton.style &&
          chatButton.style.display === 'none'
        ) {
          window.isWidgetHidden = false;
          const style = { display: 'flex', visibility: 'visible' };
          setStyle(chatButton, style);
          const statusWebchat = localStorage.getItem(
            `${SK_STATUS_WEBCHAT}-${associatedWebchatKey}`,
          );
          if (statusWebchat) {
            setStyle(iframeContainer, style);
            if (isSmallScreen) {
              disableBodyScroll(iframeContainer);
            }
          }

          executeCallback(callbackFunction);
          if (fireEvents) {
            fireWidgetShown({
              from: 'SCRIPT',
              webchatKey: associatedWebchatKey,
            });
          }
        }

        break;
      }
      case 'hide': {
        if (
          chatButton &&
          chatButton.style &&
          (!chatButton.style.display ||
            chatButton.style.display === 'block' ||
            chatButton.style.display === 'flex')
        ) {
          window.isWidgetHidden = true;
          const style = { display: 'none', visibility: 'visible' };
          setStyle(chatButton, style);
          const statusWebchat = localStorage.getItem(
            `${SK_STATUS_WEBCHAT}-${associatedWebchatKey}`,
          );

          if (statusWebchat) {
            setStyle(iframeContainer, style);
            if (isSmallScreen) {
              enableBodyScroll(iframeContainer);
            }
          }

          executeCallback(callbackFunction);
          if (fireEvents) {
            fireWidgetHidden({
              from: 'SCRIPT',
              webchatKey: associatedWebchatKey,
            });
          }
        }

        break;
      }
      default: {
        /* eslint no-console: "off" */
        console.error(
          "Sorry, there isn't any action with that name, please try with another, or contact with support.",
        );
      }
    }
  }
};

export const certainlyLandedAt = (dataOptions, callbackFunction) => {
  if (typeof dataOptions === 'object' && dataOptions !== null) {
    const { webchatKey, module } = dataOptions;
    getCertainlyTransfer({
      actionName: module,
      webchatKey,
      callback: callbackFunction,
      withoutData: true,
    });
  }
};

export const certainlyGoTo = (dataOptions, callbackFunction) => {
  if (typeof dataOptions === 'object' && dataOptions !== null) {
    const { webchatKey = '1', module, cvars, reset } = dataOptions;
    const iFrame = document.getElementById(`${IFRAME_ID_BOTXO}${webchatKey}`);

    const validCvars = getValidCvars(cvars);

    if (iFrame) {
      dataCertainlyTransfer(
        {
          data: {
            type: POST_MESSAGE_GO_TO_MODULE,
            mod_id: module,
            cvars: validCvars,
            reset,
          },
          webchatKey,
        },
        callbackFunction,
      );
    } else {
      window.newRefOptions = {
        ref: module,
        cvars: validCvars,
      };
    }
  }
};

export const certainlySendMessage = dataOptions => {
  const { webchatKey, sender, message, visible = true } = dataOptions;
  if (sender === 'bot' || sender === 'user') {
    dataCertainlyTransfer({
      data: {
        type:
          sender === 'bot'
            ? POST_MESSAGE_MESSAGE_BOT
            : POST_MESSAGE_MESSAGE_USER,
        message,
        visible,
      },
      webchatKey,
    });
  }
};

export const resetChat = ({ webchatKey = '1', callback = () => null }) => {
  let iframe = document.getElementById(`${IFRAME_ID_BOTXO}${webchatKey}`);
  const isHidden = window.isWidgetHidden;
  const web = createWebInfoToScript();

  const postMessage = element => {
    element.contentWindow.postMessage(
      {
        data: {
          type: POST_MESSAGE_RESET_CHAT,
          webchatKey,
          scriptConfig: { web },
        },
        owner: OWNER_POST_MESSAGE,
      },
      '*',
    );
  };

  const f = ({ data }) => {
    if (data.type === 'widget_ws_ready') {
      iframe = document.getElementById(`${IFRAME_ID_BOTXO}${webchatKey}`);
      postMessage(iframe);
      if (!isHidden) {
        window.certainly.widgetStatus({
          action: 'show',
          webchatKey,
          fireEvents: false,
        });
      }
      executeCallback(callback);
      window.removeEventListener('message', f, false);
    }
  };

  if (!iframe) {
    if (!isHidden) {
      window.certainly.widgetStatus({
        action: 'hide',
        webchatKey,
        fireEvents: false,
      });
    }
    window.certainly.widgetStatus({
      action: 'open',
      webchatKey,
      fireEvents: false,
    });
    window.certainly.widgetStatus({
      action: 'close',
      webchatKey,
      fireEvents: false,
    });
    window.addEventListener('message', f);
  } else if (iframe && window.certainly.wsIsReady) {
    postMessage(iframe);
    executeCallback(callback);
  } else {
    window.addEventListener('message', f);
  }
};

export const clearChat = ({ webchatKey = '1', callback = () => null }) => {
  let iframe = document.getElementById(`${IFRAME_ID_BOTXO}${webchatKey}`);
  const isHidden = window.isWidgetHidden;

  const postMessage = element => {
    element.contentWindow.postMessage(
      {
        data: {
          type: POST_MESSAGE_CLEAR_CHAT,
          webchatKey,
        },
        owner: OWNER_POST_MESSAGE,
      },
      '*',
    );
  };

  const f = ({ data }) => {
    if (data.type === 'widget_ws_ready') {
      iframe = document.getElementById(`${IFRAME_ID_BOTXO}${webchatKey}`);
      postMessage(iframe);
      if (!isHidden) {
        window.certainly.widgetStatus({
          action: 'show',
          webchatKey,
          fireEvents: false,
        });
      }
      executeCallback(callback);
      window.removeEventListener('message', f, false);
    }
  };

  if (!iframe) {
    if (!isHidden) {
      window.certainly.widgetStatus({
        action: 'hide',
        webchatKey,
        fireEvents: false,
      });
    }
    window.certainly.widgetStatus({
      action: 'open',
      webchatKey,
      fireEvents: false,
    });
    window.certainly.widgetStatus({
      action: 'close',
      webchatKey,
      fireEvents: false,
    });
    window.addEventListener('message', f);
  } else if (iframe && window.certainly.wsIsReady) {
    postMessage(iframe);
    executeCallback(callback);
  } else {
    window.addEventListener('message', f);
  }
};

export const sendConfiguration = ({ webchatKey = '1', options }) => {
  const iframe = document.getElementById(`${IFRAME_ID_BOTXO}${webchatKey}`);

  iframe.contentWindow.postMessage(
    {
      type: POST_MESSAGE_SCRIPT_GET_CONFIGURATION,
      message: options,
    },
    '*',
  );
};
