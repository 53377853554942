import baseConfig from './base';

const config = {
  appEnv: 'dist',
  applicationInsights: {
    endpointUrl: 'https://app.certainly.io/telemetry',
    instrumentationKey: 'e09e85a8-30fd-4f4f-bf18-bfefed2de24c',
  },
};

export default Object.freeze(Object.assign({}, baseConfig, config));
