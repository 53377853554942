export const DEFAULT_OPTIONS = {
  env: 'prod',
  id: undefined,
  width: 400,
  height: 600,
  right: 40,
  bottom: 40,
  buttonWidth: 56,
  buttonHeight: 56,
  buttonColor: 'transparent',
  zIndex: 1,
};

const certainlyDefaultColor = '#331A70';

export const isDefaultColor = color => {
  const formattedDefaultColor = certainlyDefaultColor.toUpperCase();
  const formattedCustomColor = color.toUpperCase();

  return formattedDefaultColor === formattedCustomColor;
};

export const SCRIPT_OPTIONS = {
  SC_BUTTON_COLOR: 'buttonColor',
  SC_MODE: 'mode',
  SC_AUTOLOAD: 'autoload',
  SC_REF: 'ref',
  SC_CLEAR_WEBCHAT_STATE: 'clearWebchatState',
  SC_ENV: 'env',
  SC_ID: 'id',
};

export const STORE_KEYS = {
  SK_STATUS_WEBCHAT: 'statusWebchat',
};

export const certainlyDomains = {
  dev: 'http://certainlyfetesting.localhost:8082',
  devBack: 'http://localhost:80/',
  test: 'https://dev.certainly.io',
  preprod: 'https://pre.certainly.io',
  prod: 'https://app.certainly.io',
  local: 'http://localhost',
};

export const certainlyURLS = {
  dev: 'http://certainlyfetesting.localhost:8082/webchat/',
  devBack: 'http://localhost:80/webchat/',
  test: 'https://dev.certainly.io/webchat/',
  preprod: 'https://pre.certainly.io/webchat/',
  prod: 'https://app.certainly.io/webchat/',
  local: 'http://localhost/webchat/',
};

export const certainlyIsDeployedURLS = {
  dev: 'https://dev.certainly.io/api/deployment/',
  devBack: 'http://localhost:80/api/deployment/',
  test: 'https://dev.certainly.io/api/deployment/',
  preprod: 'https://pre.certainly.io/api/deployment/',
  prod: 'https://app.certainly.io/api/deployment/',
  local: 'http://localhost/api/deployment/',
};

export const certainlyGetDataURLs = {
  dev: 'https://dev.certainly.io/webbot/api/webchat/',
  devBack: 'http://localhost:80/webbot/api/webchat/',
  test: 'https://dev.certainly.io/webbot/api/webchat/',
  preprod: 'https://pre.certainly.io/webbot/api/webchat/',
  prod: 'https://app.certainly.io/webbot/api/webchat/',
  local: 'http://localhost/webbot/api/webchat/',
};

export const certainlyV2Urls = {
  dev: 'http://certainlyfetesting.localhost:8083/',
  test: 'https://dev.certainly.io/webchat/v2/',
  preprod: 'https://pre.certainly.io/webchat/v2/',
  prod: 'https://app.certainly.io/webchat/v2/',
};
