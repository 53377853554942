export const getUid = (botId, webchatKey) => {
  const { certainlyData } = window;
  if (certainlyData) {
    const dataForBotIdAndWebchatKey = certainlyData[`${botId}${webchatKey}`];
    if (dataForBotIdAndWebchatKey) {
      return dataForBotIdAndWebchatKey.userId;
    }
  }
  return 'No userId for that botId and webchatKey';
};

export default {
  getUid,
};
