export const SVG_ID_CERTAINLY = 'certainly-svg';
export const IFRAME_CONTAINER_ID_BOTXO = 'botxo-iframeContainer-';
export const IFRAME_ID_BOTXO = 'botxo-iframe-';
export const BOTXO_BUTTON = 'botxo-button-';
export const CLOSE_CHAT = 'closeChat-';
export const OPEN_CHAT = 'openChat-';
export const MAXIMIZE_CHAT = 'maximizeChat-';
export const MINIMIZE_CHAT = 'minimizeChat-';
export const BOTXO_CHAT = 'botxo-chat-';
export const EVENT_CHAT = 'eventChat-';
export const INITIALIZED_WIDGET = 'initializedWidget';

//PostMessage communication
export const OWNER_POST_MESSAGE = 'BotXO';

export function setAttrs(el, attrs) {
  Object.keys(attrs).forEach(name => {
    el.setAttribute(name, attrs[name]);
  });
}

export function setStyle(el, style) {
  if (style) {
    Object.keys(style).forEach(styleName => {
      el.style[styleName] = style[styleName]; // eslint-disable-line no-param-reassign
    });
  }
}

export function createElement(type, attrs, style) {
  const el = document.createElement(type);
  setAttrs(el, attrs);
  setStyle(el, style);
  return el;
}

export function insertStyle(style) {
  const css = createElement(
    'style',
    {
      type: 'text/css',
    },
    {},
  );
  css.innerHTML = style;
  document.head.appendChild(css);
}

export function scale(n, s, odd) {
  let size = Math.round(n * s);
  const isEven = size % 2 === 0;
  if ((odd && isEven) || (!odd && !isEven)) {
    size += 1;
  }
  return size;
}

export const getCertainlyIcon = (id, altText, iconColor) => {
  const xmlns = 'http://www.w3.org/2000/svg';

  const svg = document.createElementNS(xmlns, 'svg');
  svg.setAttributeNS(null, 'viewBox', '0 0 16 8');
  svg.setAttributeNS(null, 'width', 32.6);
  svg.setAttributeNS(null, 'height', 14);
  svg.setAttributeNS(null, 'id', id);

  const title = document.createElementNS(xmlns, 'title');
  title.innerHTML = altText;
  svg.appendChild(title);

  const firstDotPath = document.createElementNS(xmlns, 'path');
  firstDotPath.setAttributeNS(
    null,
    'd',
    'M16 2.667C16 1.194 14.806 0 13.334 0c-1.473 0-2.667 1.194-2.667 2.667 0 1.472 1.194 2.666 2.667 2.666C14.806 5.333 16 4.14 16 2.667z',
  );
  firstDotPath.setAttributeNS(null, 'fill', iconColor || '#FF0049');
  firstDotPath.setAttributeNS(null, 'id', 'red-dot-path');

  const secondDotPath = document.createElementNS(xmlns, 'path');
  secondDotPath.setAttributeNS(
    null,
    'd',
    'M6.333 3.333C5.045 3.333 4 4.378 4 5.667 4 6.955 5.045 8 6.333 8c1.289 0 2.334-1.045 2.334-2.333 0-1.289-1.045-2.334-2.334-2.334z',
  );
  secondDotPath.setAttributeNS(null, 'fill', iconColor || '#FFB769');
  secondDotPath.setAttributeNS(null, 'id', 'orange-dot-path');

  const thirdDotPath = document.createElementNS(xmlns, 'path');
  thirdDotPath.setAttributeNS(
    null,
    'd',
    'M1.333 2.667C.597 2.667 0 3.264 0 4s.597 1.333 1.333 1.333c.737 0 1.334-.597 1.334-1.333S2.07 2.667 1.333 2.667z',
  );
  thirdDotPath.setAttributeNS(null, 'fill', iconColor || '#FFC1BE');
  thirdDotPath.setAttributeNS(null, 'id', 'pink-dot-path');

  svg.appendChild(firstDotPath);
  svg.appendChild(secondDotPath);
  svg.appendChild(thirdDotPath);

  return svg;
};
