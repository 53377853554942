export const REG_ALPHANUMERIC_UNDERSCORE = /^(({){2}[a-zA-Z_][a-zA-Z0-9_]*(}){2})$|^[a-zA-Z_][a-zA-Z0-9_]*$/;

export const checkRegex = (value, regex) => regex.test(value) || value === '';

export const getValidCvars = cvars => {
  let validCvars = {};

  if (cvars) {
    const cvarsInvalid = [];
    const cvarNames = Object.keys(cvars).filter(cvar => {
      const isCvarValid = checkRegex(cvar, REG_ALPHANUMERIC_UNDERSCORE);
      if (!isCvarValid) {
        cvarsInvalid.push(cvar);
      }
      return isCvarValid;
    });

    for (let i = 0; i < cvarNames.length; i += 1) {
      const key = cvarNames[i];
      validCvars = {
        ...validCvars,
        [key]: cvars[key],
      };
    }
  }

  return validCvars;
};

export const isPropValue = value => Number.isNaN(parseFloat(value));

export const existValue = value =>
  !Number.isNaN(parseFloat(value)) && parseFloat(value) > -1;

export const setQueryBackground = background =>
  background === 'transparent' ? background : background.replace('#', '%23');

export const generateUrl = ({
  id: deploymentId,
  sunshine_app_id,
  ref,
  autoload,
  url,
  greetingText,
  mode,
  noDelayMode,
  botxoUnbranded,
  webchatKey = '1',
  origin,
  pathname,
  cvars,
  background,
  version,
  debuggerMode,
  uId,
  voice,
  inputType,
}) => {
  const id = sunshine_app_id || deploymentId;

  const idHasRef = id.indexOf('?ref=');
  const idHasQueryParams = id.indexOf('?');
  let totalUrl = '';
  if (idHasRef > 0 && !ref) {
    totalUrl = `${url + id}&`;
  } else if (idHasQueryParams > 0 && idHasRef < 0) {
    totalUrl = `${url + id.slice(0, idHasQueryParams)}?`;
  } else {
    const lastRef =
      window.newRefOptions && window.newRefOptions.ref
        ? window.newRefOptions.ref
        : ref;
    totalUrl = `${url + id}${lastRef ? `?ref=${lastRef}&` : '?'}`;
  }
  totalUrl += `from_script=1&`;
  totalUrl += `${autoload ? `autoload=${autoload}&` : ''}`;
  totalUrl += `${greetingText ? `greetingText=${greetingText}&` : ''}`;
  totalUrl += `${mode ? `mode=${mode}&` : ''}`;
  totalUrl += `${noDelayMode ? `no_delay_mode=${noDelayMode}&` : ''}`;
  totalUrl += `${botxoUnbranded ? `botxo-unbranded=${botxoUnbranded}&` : ''}`;
  totalUrl += `${webchatKey ? `webchatKey=${webchatKey}&` : ''}`;
  totalUrl += `${origin ? `origin=${origin}&` : ''}`;
  totalUrl += `${pathname ? `pathname=${pathname}&` : ''}`;
  totalUrl += `${background ? `background=${background}&` : ''}`;
  totalUrl += `${version ? `version=${version}&` : ''}`;
  totalUrl += `${debuggerMode ? `debuggerMode=${debuggerMode}&` : ''}`;
  totalUrl += `${uId ? `uId=${uId}&` : ''}`;
  totalUrl += `${id ? `botId=${id}&` : ''}`;
  totalUrl += `${inputType ? `input_type=${inputType}&` : ''}`;
  if (typeof voice === 'object') {
    Object.keys(voice).forEach(key => {
      totalUrl += `${key}=${voice[key]}&`;
    });
  }
  let lastCvars =
    window.newRefOptions && window.newRefOptions.cvars
      ? window.newRefOptions.cvars
      : cvars;
  if (lastCvars && typeof lastCvars === 'object') {
    lastCvars = getValidCvars(lastCvars);
    const keys = Object.keys(lastCvars);
    const keyLength = keys.length;
    if (keyLength) {
      totalUrl += 'cvars=';
      for (let i = 0; i < keyLength; i += 1) {
        const key = keys[i];
        if (i < keyLength - 1) {
          totalUrl += `${key}^*${lastCvars[key]}!*^|`;
        } else {
          totalUrl += `${key}^*${lastCvars[key]}`;
        }
      }
    }
  }
  return totalUrl;
};

export const maximizeChat = ({ webchatKey }) => {
  const iframeContainer = document.getElementById(
    `botxo-iframeContainer-${webchatKey}`,
  );

  if (iframeContainer && iframeContainer.style) {
    iframeContainer.style.overflow = 'hidden';
    iframeContainer.style.width = '100%';
    iframeContainer.style.height = '100%';
    iframeContainer.style.bottom = '0px';
    iframeContainer.style.right = '0px';
    iframeContainer.style.maxWidth = '100%';
    iframeContainer.style.maxHeight = '100%';
    iframeContainer.style.position = 'fixed';
    iframeContainer.style.zIndex = '2';
  }
};

export const minimizeChat = ({ webchatKey }) => {
  const iframeContainer = document.getElementById(
    `botxo-iframeContainer-${webchatKey}`,
  );

  if (iframeContainer && iframeContainer.style) {
    iframeContainer.style.overflow = null;
    iframeContainer.style.width = null;
    iframeContainer.style.height = null;
    iframeContainer.style.bottom = null;
    iframeContainer.style.right = null;
    iframeContainer.style.maxWidth = null;
    iframeContainer.style.maxHeight = null;
    iframeContainer.style.zIndex = null;
    iframeContainer.style.position = 'absolute';
  }
};

export const openDebugger = ({ webchatKey }) => {
  const iframeContainer = document.getElementById(
    `botxo-iframeContainer-${webchatKey}`,
  );
  if (iframeContainer) {
    const { offsetWidth } = iframeContainer;
    if (iframeContainer && iframeContainer.style) {
      if (offsetWidth < 400) {
        iframeContainer.style.width = `${offsetWidth + 400}px`;
      } else if (offsetWidth < 500) {
        iframeContainer.style.width = `${offsetWidth + 300}px`;
      } else if (offsetWidth < 600) {
        iframeContainer.style.width = `${offsetWidth + 200}px`;
      } else if (offsetWidth < 700) {
        iframeContainer.style.width = `${offsetWidth + 100}px`;
      }
    }
  }
};

export const closeDebugger = ({ webchatKey }) => {
  const iframeContainer = document.getElementById(
    `botxo-iframeContainer-${webchatKey}`,
  );
  if (iframeContainer) {
    const { offsetWidth } = iframeContainer;
    if (offsetWidth < 900) {
      if (iframeContainer && iframeContainer.style) {
        iframeContainer.style.width = null;
      }
    }
  }
};
