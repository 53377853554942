import { IFRAME_ID_BOTXO, OWNER_POST_MESSAGE } from '../domUtils';

export const messageHandlingBeforeDisplay = data => {
  const allData = { ...data };
  const result = window.certainly.beforeDisplay({
    actions: allData.message.actions,
    from: allData.message.from,
  });
  const webchatKey = result.webchatKey || '1';
  const iFrame = document.getElementById(`${IFRAME_ID_BOTXO}${webchatKey}`);
  const objectToSend = {
    owner: OWNER_POST_MESSAGE,
    data: {
      type: 'sent_from_sdk_before_display',
      webchatKey,
      payload: {
        ...allData,
        message: {
          ...allData.message,
          actions: result.actions,
        },
      },
    },
  };

  if (iFrame) {
    iFrame.contentWindow.postMessage(objectToSend, '*');
  }
};

export const messageHandlingBeforeSend = data => {
  const allData = { ...data };
  const result = window.certainly.beforeSend({
    actions: allData.message.actions,
    from: allData.message.from,
  });
  const webchatKey = result.webchatKey || '1';
  const iFrame = document.getElementById(`${IFRAME_ID_BOTXO}${webchatKey}`);
  const objectToSend = {
    owner: OWNER_POST_MESSAGE,
    data: {
      type: 'sent_from_sdk_before_send',
      webchatKey,
      payload: {
        ...allData,
        message: {
          ...allData.message,
          actions: result.actions,
        },
      },
    },
  };

  if (iFrame) {
    iFrame.contentWindow.postMessage(objectToSend, '*');
  }
};
