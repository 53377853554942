import { BOTXO_CHAT } from './domUtils';

const unitsReg = /^\d+(px|pt|em|rem|vh|vw)$/;
let lockedBodyScrollTop = 0;

export const hideChatIcon = ({ webchatKey = '1' }) => {
  const component = document.getElementById(`${BOTXO_CHAT}${webchatKey}`);

  if (component && component.style) {
    component.style.visibility = 'hidden';
  }
};

export const showChatIcon = ({ webchatKey = '1' }) => {
  const component = document.getElementById(`${BOTXO_CHAT}${webchatKey}`);

  if (component && component.style) {
    component.style.visibility = 'visible';
  }
};

export const getValue = value => {
  if (!value || !parseInt(value, 10)) return '0px';

  return unitsReg.test(value) ? value : `${parseInt(value, 10)}px`;
};

export const disableBodyScroll = () => {
  lockedBodyScrollTop =
    (document.documentElement && document.documentElement.scrollTop) ||
    document.body.scrollTop;

  document.body.classList.add('fixed-mobile');
  if (document && document.body && document.body.style) {
    document.body.style.top = `${-lockedBodyScrollTop}px`;
  }
};

export const enableBodyScroll = () => {
  document.body.classList.remove('fixed-mobile');
  window.scrollBy(0, lockedBodyScrollTop);
  if (document && document.body && document.body.style) {
    document.body.style.top = null;
  }
};

export const getParameterByName = (paramName, url) => {
  let finalUrl = url;
  if (!finalUrl) finalUrl = window.location.href;
  const name = paramName.replace('/[[]]/g', '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(finalUrl);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace('/+/g', ' '));
};

export const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};
