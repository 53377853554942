export const palette = {
  colors: {
    white: '#FFFFFF',
    brand: '#FF0049',
  },
};

export const certainlyPalette = {
  colors: {
    white: '#FFFFFF',
    brand: '#331A70',
  },
};
