export const fireWidgetClose = (params = {}) =>
  window.dispatchEvent(
    new CustomEvent('widgetclose', {
      bubbles: false,
      cancelable: false,
      detail: params,
    }),
  );
export const fireOnWidgetClosed = (params = {}) =>
  window.dispatchEvent(
    new CustomEvent('onWidgetClosed', {
      bubbles: false,
      cancelable: false,
      detail: params,
    }),
  );

export const fireWidgetOpen = (params = {}) =>
  window.dispatchEvent(
    new CustomEvent('widgetopen', {
      bubbles: false,
      cancelable: false,
      detail: params,
    }),
  );

export const fireOnWidgetOpened = (params = {}) =>
  window.dispatchEvent(
    new CustomEvent('onWidgetOpened', {
      bubbles: false,
      cancelable: false,
      detail: params,
    }),
  );

export const fireWidgetShown = (params = {}) =>
  window.dispatchEvent(
    new CustomEvent('onWidgetShown', {
      bubbles: false,
      cancelable: false,
      detail: params,
    }),
  );

export const fireWidgetHidden = (params = {}) =>
  window.dispatchEvent(
    new CustomEvent('onWidgetHidden', {
      bubbles: false,
      cancelable: false,
      detail: params,
    }),
  );

export const fireEvent = (event, params = {}) =>
  window.dispatchEvent(
    new CustomEvent(event, {
      bubbles: false,
      cancelable: false,
      detail: params,
    }),
  );

export const BOT_OPEN = 'Script:Bot Open';
export const CONVERSION = 'Script:Conversion';
export const WEB_LOAD = 'Script:Web Load';
