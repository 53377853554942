import { certainlyV2Urls } from '../config';
import { createElement } from '../domUtils';

export const loadMobileLayout = ({ botDeploymentId, env = 'prod' }) => {
  if (!botDeploymentId) {
    return;
  }

  const url = `${certainlyV2Urls[env]}?botDeploymentId=${botDeploymentId}`;

  const iframe = createElement(
    'iframe',
    {
      id: 'certainly-iframe',
      name: 'certainly-iframe',
      src: url,
      tabIndex: 0,
      title: 'Chatbot Dialogue by Certainly',
    },
    {
      border: 'none',
      bottom: 0,
      height: '2.5rem',
      left: 0,
      overflow: 'hidden',
      position: 'absolute',
      width: '100%',
    },
  );

  document.body.appendChild(iframe);

  window.addEventListener('message', event => {
    switch (event.data.message) {
      case 'EXPANDED': {
        iframe.style.height = '55vh';
        break;
      }
      case 'COLLAPSED': {
        setTimeout(() => {
          iframe.style.height = '2.5rem';
        }, 500);
        break;
      }
      default:
    }
  });
};
